<template>
  <el-dialog
    class="good-modal"
    title="选择商品"
    :visible="value"
    width="900px"
    :before-close="handleClose"
  >
    <div>
      <div class="nav">
        <el-input
          placeholder="请输入商品名称"
          v-model="goodsName"
          size="mini"
          style="width: 220px"
          clearable
        >
          <el-button
            type="primary"
            size="mini"
            slot="append"
            @click="searchClick"
            icon="el-icon-search"
          ></el-button>
        </el-input>
      </div>
      <div class="box">
        <el-table
          v-loading="tbLoad"
          :data="tbList"
          height="450"
          border
          style="width: 100%"
          size="small"
          :header-cell-style="{
            background: '#d9dde1',
            color: '#0d0202',
          }"
        >
          <el-table-column
            align="center"
            prop="goodsId"
            label="商品ID"
          ></el-table-column>
          <el-table-column align="center"  label="图片">
            <template slot-scope="{ row }">
              <el-image 
                class="el_image_model_icon"
                style="width: 100px; height: 100px"
                :src="row.imageUrls.split(',')[0]" 
                :preview-src-list="row.imageUrls.split(',')">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="goodsName"
            label="商品名称"
          ></el-table-column>
          <el-table-column align="center" prop="id" label="操作">
            <template slot-scope="{ row }">
              <el-button :disabled="row.isSelected" @click="selectGood(row)" type="text">{{row.isSelected ? '已选择' : '选择'}}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          @current-change="changePage"
          @size-change="changePageSize"
          :total="page.total"
          :page-size="page.pageSize"
          :current-page="page.page"
        />
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { skuGoodList } from "@/api/order";
export default {
  props: {
    value: {
      default: false,
    },
    selectGoods: Object
  },
  data() {
    return {
      tbList: [],
      tbLoad: false,
      goodsName: "",
      page: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getList();
      }
    },
  },
  methods: {
    selectGood(row) {
      this.$emit("select", row);
      // this.handleClose();
    },
    getList() {
      this.tbLoad = true;
      const pars = {
        // storeId: '47013032788852736',
        goodsName: this.goodsName,
        currentPage: this.page.page,
        pageSize: this.page.pageSize,
      };
      this.$axios.post(this.$api.cmty_pur_store_goods.getStoreGoodsList, pars)
      .then((res) => {
        this.tbLoad = false;
        if (res.code === 0) {
          this.tbList = res.data.records;
          this.page.total = Number(res.data.total);
        } else {
          this.tbList = [];
        }
      })
    },
    searchClick() {
      this.page.page = 1;
      this.getList();
    },
    handleClose(done) {
      this.$emit("input", false);
      done && done();
    },
    changePage(p) {
      this.page.page = p;
      this.getList();
    },
    changePageSize(s) {
      this.page.page = 1;
      this.page.pageSize = s;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.good-modal {
  .nav {
    margin-bottom: 15px;
  }
  .box {
    .good-img {
      width: 100px;
    }
  }
}
</style>
<style lang="scss">
.el_image_model_icon .el-icon-circle-close {
  color: #fff!important;
}
</style>