var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "",
        visible: _vm.showCommissionModel,
        width: "500px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showCommissionModel = $event
        },
      },
    },
    [
      _c("div", [
        _c("div", [
          _c(
            "div",
            {
              staticStyle: {
                "margin-bottom": "5px",
                "font-size": "18px",
                "font-weight": "bold",
              },
            },
            [_vm._v("起购设置")]
          ),
          _c(
            "div",
            { staticStyle: { "margin-bottom": "20px" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.buyingType,
                    callback: function ($$v) {
                      _vm.buyingType = $$v
                    },
                    expression: "buyingType",
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "5px" } },
                    [
                      _c(
                        "el-radio",
                        { attrs: { label: 1 } },
                        [
                          _vm._v("起购数量 "),
                          _vm.buyingType === 1
                            ? _c("el-input-number", {
                                attrs: {
                                  min: 1,
                                  max: 999999,
                                  label: "起购数量",
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.buyingNum,
                                  callback: function ($$v) {
                                    _vm.buyingNum = $$v
                                  },
                                  expression: "buyingNum",
                                },
                              })
                            : _vm._e(),
                          _vm._v("（件） "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "5px" } },
                    [
                      _c(
                        "el-radio",
                        { attrs: { label: 2 } },
                        [
                          _vm._v("起购金额 "),
                          _vm.buyingType === 2
                            ? _c("el-input-number", {
                                attrs: {
                                  min: 0.01,
                                  max: 999999,
                                  label: "起购金额",
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.buyingNum,
                                  callback: function ($$v) {
                                    _vm.buyingNum = $$v
                                  },
                                  expression: "buyingNum",
                                },
                              })
                            : _vm._e(),
                          _vm._v("（元） "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "margin-bottom": "5px",
                "font-size": "18px",
                "font-weight": "bold",
              },
            },
            [_vm._v("佣金设置")]
          ),
          _c(
            "div",
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.commissionType,
                    callback: function ($$v) {
                      _vm.commissionType = $$v
                    },
                    expression: "commissionType",
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "5px" } },
                    [
                      _c(
                        "el-radio",
                        { attrs: { label: 1 } },
                        [
                          _vm._v("固定佣金 "),
                          _vm.commissionType === 1
                            ? _c("el-input-number", {
                                attrs: {
                                  min: 0.01,
                                  max: 999999,
                                  label: "固定佣金",
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.commissionTypeNum,
                                  callback: function ($$v) {
                                    _vm.commissionTypeNum = $$v
                                  },
                                  expression: "commissionTypeNum",
                                },
                              })
                            : _vm._e(),
                          _vm._v("（元） "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content:
                                  "每卖出一件该商品，商家需给到团长对应佣金。",
                                placement: "right",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-warning-outline",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "5px" } },
                    [
                      _c(
                        "el-radio",
                        { attrs: { label: 2 } },
                        [
                          _vm._v("比例佣金 "),
                          _vm.commissionType === 2
                            ? _c("el-input-number", {
                                attrs: {
                                  min: 0.01,
                                  max: 100,
                                  label: "比例佣金",
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.commissionTypeNum,
                                  callback: function ($$v) {
                                    _vm.commissionTypeNum = $$v
                                  },
                                  expression: "commissionTypeNum",
                                },
                              })
                            : _vm._e(),
                          _vm._v("（%） "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content:
                                  "该商品在一个团内实际成交额的百分比金额给到团长当作佣金。",
                                placement: "right",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-warning-outline",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }