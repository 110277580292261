var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "outer" },
        [
          _c(
            "search-panel",
            {
              attrs: { flex: "", "show-btn": "" },
              on: { getList: _vm.getSearchList },
            },
            [
              _c(
                "div",
                [
                  _c("p", [_vm._v("商品名称")]),
                  _c("el-input", {
                    staticStyle: { width: "240px" },
                    attrs: { size: "mini", placeholder: "请输入商品名称" },
                    model: {
                      value: _vm.goods_name,
                      callback: function ($$v) {
                        _vm.goods_name = $$v
                      },
                      expression: "goods_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: {
                    slot: "button-right",
                    plain: "",
                    type: "primary",
                    size: "mini",
                  },
                  on: { click: () => (_vm.showGoodsModal = true) },
                  slot: "button-right",
                },
                [_vm._v(" 选择商品 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "35px" },
                  attrs: {
                    slot: "button-right",
                    type: "primary",
                    size: "mini",
                  },
                  on: { click: _vm.showCard },
                  slot: "button-right",
                },
                [_vm._v(" 邀请团长 ")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "element-loading-text": "拼命加载中",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "goodsId", label: "商品ID" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "图片", "min-width": "80px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-image", {
                          staticClass: "el_image_model_icon",
                          staticStyle: { width: "50px", height: "50px" },
                          attrs: {
                            src: row.imageUrls.split(",")[0],
                            "preview-src-list": row.imageUrls.split(","),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "goodsName", label: "商品名称" },
              }),
              _c("el-table-column", {
                attrs: { label: "价格（元）", prop: "price" },
              }),
              _c("el-table-column", {
                attrs: { label: "库存（件）", prop: "groupCount" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.storeGoodsSkuVOList.reduce((prev, cur) => {
                                return prev + cur.stockQuantity
                              }, 0)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "起购数（件）", prop: "groupCount" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.buyCondition.startBuyNum > 0
                          ? _c("span", [
                              _vm._v(_vm._s(row.buyCondition.startBuyNum)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "起购金额（元）", prop: "groupCount" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.buyCondition.startBuyMoney > 0
                          ? _c("span", [
                              _vm._v(_vm._s(row.buyCondition.startBuyMoney)),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "佣金设置" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.buyCondition.commissionType > 0
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  row.buyCondition.commissionType === 1
                                    ? "固定佣金：" +
                                        row.buyCondition.fixedCommission +
                                        "元"
                                    : "比例佣金：" +
                                        row.buyCondition
                                          .proportionalCommission +
                                        "%"
                                )
                              ),
                            ])
                          : _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("未设置"),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "140", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.toEdit(row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "red",
                            staticStyle: { "margin-left": "20px" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.outGroup(row)
                              },
                            },
                          },
                          [_vm._v(" 退出团购 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.pagination.currentPage,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.pagination.nowPageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pagination.count,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "邀请团长扫码",
            visible: _vm.showCodeImg,
            width: "300px",
            "before-close": () => (_vm.showCodeImg = false),
          },
          on: {
            "update:visible": function ($event) {
              _vm.showCodeImg = $event
            },
            open: _vm.openCodeImg,
          },
        },
        [
          _c("div", [
            _c("img", {
              staticStyle: { width: "100%" },
              attrs: { src: _vm.codeBase64, alt: "" },
            }),
          ]),
        ]
      ),
      _c("GoodsModal", {
        on: { select: _vm.selectGood },
        model: {
          value: _vm.showGoodsModal,
          callback: function ($$v) {
            _vm.showGoodsModal = $$v
          },
          expression: "showGoodsModal",
        },
      }),
      _c("CommissionModel", {
        attrs: { goodsDetail: _vm.goodsDetail },
        on: { getList: _vm.getList },
        model: {
          value: _vm.showCommissionModel,
          callback: function ($$v) {
            _vm.showCommissionModel = $$v
          },
          expression: "showCommissionModel",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }