<template>
  <div class="app-container">
    <div class="outer">
      <search-panel
        flex
        show-btn
        @getList="getSearchList"
      >
        <div>
          <p>商品名称</p>
          <el-input
            v-model="goods_name"
            size="mini"
            style="width: 240px"
            placeholder="请输入商品名称"
          />
        </div>

        <el-button
          slot="button-right"
          plain
          style="margin-left:10px"
          type="primary"
          size="mini"
          @click="() => showGoodsModal=true"
        >
          选择商品
        </el-button>

        <el-button
          slot="button-right"
          style="margin-left: 35px"
          type="primary"
          size="mini"
          @click="showCard"
        >
          邀请团长
        </el-button>
      </search-panel>
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        element-loading-text="拼命加载中"
        style="width: 100%"
      >
        <el-table-column
          prop="goodsId"
          label="商品ID"
        />
        <el-table-column align="center"  label="图片" min-width= "80px">
          <template slot-scope="{ row }">
            <el-image 
              class="el_image_model_icon"
              style="width: 50px; height: 50px"
              :src="row.imageUrls.split(',')[0]" 
              :preview-src-list="row.imageUrls.split(',')">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="goodsName"
          label="商品名称"
        />
        <el-table-column
          label="价格（元）"
          prop="price"
        />
        <el-table-column
          label="库存（件）"
          prop="groupCount"
        >
          <template slot-scope="{ row }">
            {{row.storeGoodsSkuVOList.reduce((prev, cur)=>{
              return prev + cur.stockQuantity
            }, 0)}}
          </template>
        </el-table-column>
        <el-table-column
          label="起购数（件）"
          prop="groupCount"
        >
          <template slot-scope="{ row }">
            <span v-if="row.buyCondition.startBuyNum > 0">{{row.buyCondition.startBuyNum}}</span>
            <!-- <span v-else style="color: red">未设置</span> -->
          </template>
        </el-table-column>
        <el-table-column
          label="起购金额（元）"
          prop="groupCount"
        >
          <template slot-scope="{ row }">
            <span v-if="row.buyCondition.startBuyMoney > 0">{{row.buyCondition.startBuyMoney}}</span>
            <!-- <span v-else style="color: red">未设置</span> -->
          </template>
        </el-table-column>
        <el-table-column
          label="佣金设置"
        >
          <template slot-scope="{ row }">
            <span v-if="row.buyCondition.commissionType > 0">{{row.buyCondition.commissionType === 1 ? '固定佣金：' + row.buyCondition.fixedCommission + '元' : '比例佣金：' + row.buyCondition.proportionalCommission + '%'}}</span>
            <span v-else style="color: red">未设置</span>
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          width="140"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              @click="toEdit(row)"
            >
              编辑
            </el-button>

            <el-button
              type="text"
              class="red"
              style="margin-left: 20px"
              @click="outGroup(row)"
            >
              退出团购
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.nowPageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.count"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      title="邀请团长扫码"
      :visible.sync="showCodeImg"
      width="300px"
      @open="openCodeImg"
      :before-close="()=> showCodeImg = false">
      <div>
        <img style="width: 100%;" :src="codeBase64" alt="">
      </div>
    </el-dialog>
     <GoodsModal @select="selectGood" v-model="showGoodsModal"></GoodsModal>

     <CommissionModel :goodsDetail="goodsDetail" v-model="showCommissionModel" @getList="getList"></CommissionModel>
  </div>
</template>

<script>
import GoodsModal from "./GoodsModal.vue";
import CommissionModel from "./CommissionModel.vue";
export default {
  name: '',
  components: {
    GoodsModal,
    CommissionModel
  },
  props: {},
  data () {
    return {
      goods_name: '',
      showCodeImg: false, //二维码弹窗
      codeBase64: '',
      tableData: [],
      goodsDetail: {},//佣金设置
      showGoodsModal: false,//商品弹窗
      showCommissionModel: false,//佣金弹窗
      loading: false,
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0
      },
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {
    this.getList()
  },
  methods: {
    getSearchList() {
      this.pagination.currentPage = 1
      this.getList()
    },
    handleSizeChange(val) {
      this.pagination.nowPageSize = val
      this.pagination.currentPage = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.getList()
    },
    // 获取列表数据
    getList(index) {
      const _this = this
      _this.loading = true
      _this.$axios
        .post(_this.$api.cmty_pur_store_goods.getCmtyPurStoreGoodsList, {
          currentPage: _this.pagination.currentPage,
          pageSize: _this.pagination.nowPageSize,
          goodsName: _this.goods_name,
        })
        .then((res) => {
          if (res.code === 0) {
            const { total } = res.data
            _this.pagination.count = Number(total);
            _this.tableData = res.data.records
          } else {
            _this.tableData = []
          }
          _this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    //展示二维码
    showCard() {
      this.showCodeImg = true;
    },
    openCodeImg() {
      if(!this.codeBase64) {
        this.$axios.get(this.$api.cmty_pur_store_goods.getQrCode, {
        })
          .then(({code, data}) => {
            if(code === 0) {
              this.codeBase64 = data;
            }
          })
      }
    },
    //编辑
    toEdit(item) {
      this.goodsDetail = item;
      this.showCommissionModel = true;
    },
    // 退出拼团
    outGroup(item) {
      this.$confirm('退出团购后，团长将无法在店铺或招募页搜索到该商品，已下单的商品依然有效，确认退出？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$api.cmty_pur_store_goods.delete + '/' + item.id)
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('操作成功');
            this.getList();
          }
        })
      }).catch(() => {         
      });
    },
    selectGood(item) {
      console.log(item)
      this.$axios.post(this.$api.cmty_pur_store_goods.addCmtyPurStoreGoods, {
        goodsIds: [item.goodsId],
        // storeId: '47013032788852736'
      })
      .then((res) => {
        if (res.code === 0) {
          this.handleCurrentChange();
          this.goodsDetail = {
            id: res.data,
            buyCondition: {
              commissionType: 0,
              fixedCommission: 0,
              proportionalCommission: 0,
              startBuyMoney: 0,
              startBuyNum: 0,
              startBuyType: 0,
            }
          };
          this.showGoodsModal = false;
          this.showCommissionModel = true;
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">

</style>