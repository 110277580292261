<template>
  <el-dialog
    title=""
    :visible.sync="showCommissionModel"
    width="500px"
    :before-close="handleClose">
    <div>
      <div>
        <div style="margin-bottom: 5px; font-size: 18px;font-weight: bold;">起购设置</div>
        <div style="margin-bottom: 20px;">
          <el-radio-group v-model="buyingType">
            <div style="margin-bottom: 5px">
              <el-radio :label="1">起购数量
                <el-input-number v-if="buyingType === 1" v-model="buyingNum" :min="1" :max="999999" label="起购数量" size="mini">
                </el-input-number>（件）
              </el-radio>
            </div>
            <div style="margin-bottom: 5px">
              <el-radio :label="2">起购金额
                <el-input-number v-if="buyingType === 2" v-model="buyingNum" :min="0.01" :max="999999" label="起购金额" size="mini">
                </el-input-number>（元）
              </el-radio>
            </div>
          </el-radio-group>
        </div>
        <div style="margin-bottom: 5px; font-size: 18px;font-weight: bold;">佣金设置</div>
        <div>
          <el-radio-group v-model="commissionType">
            <div style="margin-bottom: 5px">
              <el-radio :label="1">固定佣金
                <el-input-number v-if="commissionType === 1" v-model="commissionTypeNum" :min="0.01" :max="999999" label="固定佣金" size="mini">
                </el-input-number>（元）
                <el-tooltip class="item" effect="dark" content="每卖出一件该商品，商家需给到团长对应佣金。" placement="right">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-radio>
            </div>
            <div style="margin-bottom: 5px">
              <el-radio :label="2">比例佣金
                <el-input-number v-if="commissionType === 2" v-model="commissionTypeNum" :min="0.01" :max="100" label="比例佣金" size="mini">
                </el-input-number>（%）
                <el-tooltip class="item" effect="dark" content="该商品在一个团内实际成交额的百分比金额给到团长当作佣金。" placement="right">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </el-radio>
            </div>
          </el-radio-group>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose" size="small">取 消</el-button>
      <el-button type="primary" @click="onSubmit" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    showCommissionModel: {
      type: Boolean,
      default: false,
    },
    goodsDetail: {
      type: Object,
      default: {

      },
    },
  },
  model: {
    prop: 'showCommissionModel',//绑定的值，通过父组件传递
    event: 'update',//自定义时间名
  },
  watch: {
    goodsDetail: {
      handler: function(row){
        console.log(item,88888)
        let item = row.buyCondition;
        this.buyingType = item.startBuyType;
        this.buyingNum = item.startBuyType === 1 ? item.startBuyNum : item.startBuyMoney;
        this.commissionType = item.commissionType;
        this.commissionTypeNum = item.commissionType === 1 ? item.fixedCommission : item.proportionalCommission;
      },
      deep: true,
    }
  },
  data () {
    return {
      buyingType: 1,
      buyingNum: 1,
      commissionType: 1,
      commissionTypeNum: 1,
    }
  },
  created () {},
  mounted () {
    console.log(this.buyCondition)
  },
  methods: {
    handleClose() {
      this.$emit('update', false);
    },
    onSubmit() {
      if(this.buyingType != 1 && this.buyingType != 2) {
        this.$message.warning('请选择起购规则');
        return;
      }
      if(this.commissionType != 1 && this.commissionType != 2) {
        this.$message.warning('请选择佣金规则');
        return;
      }
      this.$axios.post(this.$api.cmty_pur_store_goods.editCmtyPurStoreGoods, {
        id: this.goodsDetail.id,
        commissionType: this.commissionType,
        fixedCommission: this.commissionType === 1 ? this.commissionTypeNum : '',
        proportionalCommission: this.commissionType === 2 ? this.commissionTypeNum : '',
        startBuyType: this.buyingType,
        startBuyNum: this.buyingType === 1 ? this.buyingNum : '',
        startBuyMoney: this.buyingType === 2 ? this.buyingNum : '',
      })
      .then((res) => {
        if (res.code === 0) {
          this.handleClose();
          this.$message.success('修改成功');
          this.$emit('getList');
        }
      })
      
    }
  }
}
</script>

<style scoped lang="scss">

</style>